import { PublicKey } from '@solana/web3.js';
import { BigNumber } from 'bignumber.js';
import { isAddress } from 'ethers/lib/utils';
import { capitalize } from 'lodash';

import { PaymentPage } from '@/apexModules/payment-page/types/paymentPage';
import { CHAIN_CONFIG, ECryptoNetworkSymbol } from '@/constants/chains';
import { EDeployTag, EMode } from '@/constants/enum';
import { EBreezeSDK, EPaymentFlow, EPresentmentCurrency, EPresentmentCurrencyUnits } from '@/constants/payment';
import { ECryptoCurrencySymbol } from '@/constants/tokens';

export const isDev = process.env.NODE_ENV === 'development';
export const isProdDeployEnv = process.env.REACT_APP_DEPLOY_TAG === EDeployTag.PRODUCTION;
export const isInIframe = window.self !== window.top;

export const getDataType = (data: unknown): string => {
  return (Object.prototype.toString.call(data).match(/\s(\w+)\]/) as string[])[1];
};

export const formatHash = (str: string, len?: number) => {
  if (!str) return str;
  len = len ? len / 2 : 6;
  return `${str.slice(0, len)}...${str.slice(-len)}`;
};

export const formatImageUrl = (str: string) => {
  if (!str) return str;
  return `${str.slice(-10)}`;
};

export const formatNetwork = (str?: ECryptoNetworkSymbol | string) => {
  if (!str) return '';
  return capitalize(str as string);
};

export const isNumber = (input: string) => {
  return /^\d*(\.\d*)?(e[+-]?\d+)?$/.test(input);
};

// eg: 1.1/100===0.011000000000000001
export const amountDisplay = (amount: number, currency: EPresentmentCurrency | ECryptoCurrencySymbol): string => {
  if (isNaN(amount)) {
    return '-';
  }
  amount = Number(BigNumber(amount).div(Math.pow(10, EPresentmentCurrencyUnits[currency as 'SOL'])));
  return amount.toFixed(EPresentmentCurrencyUnits[currency as 'SOL']);
};

// eg: 0.011*100===1.0999999999999999
export const amountConversion = (amount: number, currency: ECryptoCurrencySymbol): string => {
  return BigNumber(amount)
    .times(Math.pow(10, EPresentmentCurrencyUnits[currency as 'SOL']))
    .toFixed(0);
};

export function isValidEmail(email: string) {
  const reg =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
export function isValidUrl(url: string) {
  const reg =
    /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(#[-a-z\d_]*)?$/i;
  return reg.test(url);
}

export const isValidEvmAddress = (address: string): boolean => {
  return isAddress(address);
  // const reg = /^(0x)?[0-9a-fA-F]{40}$/;
  // return reg.test(address);
};

export const isValidSolanaAddress = (address: string): boolean => {
  try {
    new PublicKey(address);
    return true;
  } catch (_e) {
    return false;
  }

  // const reg = /^(?:[1-9A-HJ-NP-Za-km-z]{32})$/;
  // return reg.test(address);
};

export const isValidAddress = (address: string): boolean => {
  return isValidEvmAddress(address) || isValidSolanaAddress(address);
};

export function trimObjValues(obj: Record<string, unknown>) {
  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === 'string') {
      obj[key] = (obj[key] as string).trim();
    }
  });
  return obj;
}

export const retry = <T>(func: () => Promise<T>, times: number): Promise<T> => {
  let timer: TSetTimeout;
  return new Promise((resolve, reject) => {
    const tFn = async () => {
      try {
        const res = await func();
        if (res) {
          clearTimeout(timer);
          resolve(res);
        }
      } catch (e) {
        if (times-- > 0) {
          timer = setTimeout(() => {
            tFn();
          }, 1000);
        } else {
          reject();
        }
      }
    };
    return tFn();
  });
};

export const isWalletCreated = (wallet?: BeamoNS.IWalletAddress | null) => {
  return !!wallet && !!wallet.solanaAddress && !!wallet.evmAddress;
};

export const deploymentHost = (() => {
  const deploymentTag = process.env.REACT_APP_DEPLOY_TAG as EDeployTag;
  if (deploymentTag === EDeployTag.PRODUCTION) {
    return 'breeze.cash';
  }
  return `${deploymentTag || EDeployTag.DEVELOPMENT_DP}.breeze.cash`;
})();

export const paymentHost = `pay.${deploymentHost}`;
export const linkHost = `link.${deploymentHost}`;

/**
 * Convenient way to combine multiple css classes. Example:
 * ```
 * <div className={cx(styles.root, stylesOverride.root, isOpen && 'open')}>Content</div>
 * ```
 */
export const cx = (...classNames: (string | false | undefined)[]) => classNames.filter(Boolean).join(' ');

/**
 * Get the default style value of a css property.
 * @param propertyName
 */
export const getDefaultStyle = (key: string) => getComputedStyle(document.documentElement).getPropertyValue(key);

export function injectCss(customCssContent: string) {
  const styleElement = document.createElement('style');
  styleElement.textContent = customCssContent;
  document.head.appendChild(styleElement);
}

/**
 * transform theme object to css variable and inject customCssContent
 * @param theme object, customCssContent
 * @example themeToCssVars({ background: 'red', fontSize: '16px'}) => { '--background': 'red', '--fontSize': '16px' }
 */
export const themeToCssVars = ({ customCssContent, ...theme }: BeamoNS.ITheme) => {
  const res: Record<string, string | number> = {};
  if (theme) {
    for (const [key, value] of Object.entries(theme)) {
      const suffix = /Width|Radius$/.test(key) ? 'px' : '';
      res['--' + key] = value + suffix;
    }

    if (customCssContent) {
      injectCss(customCssContent);
    }
  }
  return res;
};

export const delay = (ms: number) => new Promise((res) => setTimeout(res, ms));

export const getExplorerUrl = ({
  network,
  hash,
  mode = EMode.LIVE,
}: {
  network: ECryptoNetworkSymbol;
  hash?: string;
  mode?: EMode;
}) => {
  if (!hash) return '';

  const blockExplorer = CHAIN_CONFIG[mode][network]?.blockExplorer;
  return `${blockExplorer}tx/${hash}`;
};

export const isBreezeAccountRequired = ({
  paymentFlow,
  pageData: { isBreezeAccountRequiredForCardPayment },
}: {
  paymentFlow: EPaymentFlow;
  pageData: PaymentPage.IPageData;
}) => {
  return EPaymentFlow.Card === paymentFlow && isBreezeAccountRequiredForCardPayment;
};

export function isSubdomainOf(subdomain: string, domain: string) {
  const subdomainParts = subdomain.split('.').reverse();
  const domainParts = domain.split('.').reverse();

  if (subdomainParts.length < domainParts.length) {
    return false;
  }

  return domainParts.every((part, index) => part === subdomainParts[index]);
}

export const postIframeMessage = (type: EBreezeSDK, payload?: Record<string, unknown>) => {
  // console.log(`[Breeze]post message: ${type} ${data}`);
  window.parent.postMessage(
    {
      type,
      payload,
    },
    '*'
  );
};

export const shortenString = (
  str?: string,
  { heading = 6, trailing = 4 }: { heading?: number; trailing?: number } | undefined = {}
) => {
  if (!str) {
    return '';
  }
  if (str.length <= heading + trailing + 3) {
    return str;
  }
  return `${str.slice(0, heading)}...${str.slice(-trailing)}`;
};

export const getUrlParam = (name: string): string | null => {
  const queryString = window.location.search;
  const params = new URLSearchParams(queryString);
  return params.get(name);
};
